import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from '@shared/services/session.service';
import { GeneratedSessionService, GeneratedUserAccountService } from '@shared/generated/services';
import { TranslationService } from '@shared/services/translation.service';
import { REQUEST_PROTOCOL } from '../../../express.tokens';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { ILocalizations } from '@shared/models/localization-interfaces';
import { ConfigurationService } from '@shared/services/configuration.service';
import { environment } from '@environment';
import { catchError, timeout } from 'rxjs/operators';
import { isPlatformServer } from '@angular/common';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

  private logoutToastRef?: ActiveToast<unknown>;
  private readonly pathsToExcludeFrom401Handling: string[] = [
    GeneratedSessionService.SignInPath,
    GeneratedSessionService.SignInExternalPath,
    GeneratedSessionService.SignInSixbidPath,

    GeneratedUserAccountService.EnableTwoFactorEmailPath,
    GeneratedUserAccountService.DisableTwoFactorEmailPath,
    GeneratedUserAccountService.EnableTwoFactorAuthenticatorPath,
    GeneratedUserAccountService.RemoveTwoFactorAuthenticatorPath,
    GeneratedUserAccountService.ChangePasswordPath,
    GeneratedUserAccountService.AssignExternalLoginPath,
    GeneratedUserAccountService.UnassignExternalLoginPath,
    GeneratedUserAccountService.GenerateTwoFactorRecoveryCodesPath,
    GeneratedUserAccountService.RegisterSixbidUserAccountPath,
    GeneratedUserAccountService.RegisterV1UserAccountPath
  ];

  private readonly apiRoutes: string[] = [
    '/api',
    '/licenses.json',
    '/custom.css'
  ];

  private readonly isServer: boolean;

  constructor(private sessionService: SessionService,
              private router: Router,
              private translateService: TranslateService,
              private translationService: TranslationService,
              private configService: ConfigurationService,
              private toastr: ToastrService,
              @Optional() @Inject(REQUEST_PROTOCOL) private requestProtocol: string,
              @Inject(PLATFORM_ID) plattformId: string) {
    this.isServer = isPlatformServer(plattformId);
  }

  public get translation(): ILocalizations {
    return this.translationService.translation;
  }


  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<any> {
    const isServer = this.isServer;
    // prevents ssr for api- and asset-requests via the app & adds the referer header which might be missing in some cases
    req = req.clone({ setHeaders: { 'prevent-ssr-fallthrough': '1', 'referer': this.router.url } });
    // handle api-requests
    if (this.apiRoutes.some((p) => req.url.startsWith(p))) {
      return next.handle(req.clone({
        url: isServer ? environment.apiUrl + req.url : req.url,
        setHeaders: {
          'Cache-Control': 'no-cache',
          'Accept-Language': req.headers.get('Accept-Language') ?? this.translateService.currentLang ?? this.configService.configuration?.defaultSiteCultureName ?? 'de-de',
          'X-API-Version': req.url.includes('debug') ? '0.0-DEBUG' : environment.apiVersion
        }
      })).pipe(timeout(!this.isServer ? 30000 : 5000))
        .pipe(catchError((error) => this.handleApiError(error, req.url)));
    }
    // handle non-api request e.g. assets
    else if (isServer && !req.url.startsWith('http')) {
      const fullUrl = `${this.getBaseUrl()}${req.url}`;
      return next.handle(req.clone({ url: fullUrl }));
    }
    return next.handle(req);
  }

  private getBaseUrl(): string {
    const port = (process.env['PORT'] ?? 4000);
    return `${this.requestProtocol}://localhost:${port}`;
  }

  private handleApiError(error: HttpErrorResponse, requestUrl: string): Observable<string> {
    // TODO try login via one-click-login before logging out
    if (error.status === 401 && !this.pathsToExcludeFrom401Handling.includes(requestUrl)) {
      this.logoutToastRef?.toastRef.close();
      this.logoutToastRef = this.toastr.info(this.translation.shared.notification.sessionExpired);
      this.logoutToastRef!.onTap.subscribe(() => this.sessionService.loginRequired$.next());
      this.sessionService.signOut(this.router.url, true);
    }
    return throwError(error);
  }

}
